
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    
    window.mdb = require('mdb-ui-kit');

    window.dt = require('datatables.net');

    require('jszip');
    require('pdfmake');
    require('datatables.net-bs5');
    //require( 'datatables.net-editor-bs5' );
    require('datatables.net-autofill-bs5');
    require('datatables.net-buttons-bs5');
    require('datatables.net-buttons/js/buttons.colVis.js');
    require('datatables.net-buttons/js/buttons.html5.js');
    require('datatables.net-buttons/js/buttons.print.js');
    require('datatables.net-colreorder-bs5');
    require('datatables.net-datetime');
    require('datatables.net-fixedcolumns-bs5');
    require('datatables.net-fixedheader-bs5');
    require('datatables.net-keytable-bs5');
    require('datatables.net-responsive-bs5');
    require('datatables.net-rowgroup-bs5');
    require('datatables.net-rowreorder-bs5');
    require('datatables.net-scroller-bs5');
    require('datatables.net-searchbuilder-bs5');
    require('datatables.net-searchpanes-bs5');
    require('datatables.net-select-bs5');
    //require( 'datatables.net-staterestore-bs5' );

    require('sweetalert');

} catch (e) { 
    console.log(e);
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    //window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from "laravel-echo"
//
// window.Echo = new Echo({
//     namespace: 'App.Event', //defaults to App.Event
//     broadcaster:   'socket.io',
//     host: 'http://www.spa-merchant.local:6001'
// });
// window.Echo.channel('test')
//     .listen('App\\Events\\TestEvent', (e) => {
//         console.log(e);
//     });
