
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');



// window.Vue = require('vue');
// import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/index.css';
// Vue.use(VueToast);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/Notifications.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
//
//Vue.component('notification', require('./components/Notifications.vue').default);
//Vue.component('profile', require('./components/Profile.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
// with ES6 import

// const app = new Vue({
//       el: '#app',
// });



$(function () {
      $("#show_hide_password i").on('click', function (event) {
            event.preventDefault();
            if ($('#show_hide_password input').attr("type") == "text") {
                  $('#show_hide_password input').attr('type', 'password');
                  $('#show_hide_password i').removeClass("fa-eye");
                  $('#show_hide_password i').addClass("fa-eye-slash");
            } else if ($('#show_hide_password input').attr("type") == "password") {
                  $('#show_hide_password input').attr('type', 'text');
                  $('#show_hide_password i').removeClass("fa-eye-slash");
                  $('#show_hide_password i').addClass("fa-eye");
            }
      });

      $("#show_hide_confirm_password i").on('click', function (event) {
            event.preventDefault();
            if ($('#show_hide_confirm_password input').attr("type") == "text") {
                  $('#show_hide_confirm_password input').attr('type', 'password');
                  $('#show_hide_confirm_password i').removeClass("fa-eye");
                  $('#show_hide_confirm_password i').addClass("fa-eye-slash");
            } else if ($('#show_hide_confirm_password input').attr("type") == "password") {
                  $('#show_hide_confirm_password input').attr('type', 'text');
                  $('#show_hide_confirm_password i').removeClass("fa-eye-slash");
                  $('#show_hide_confirm_password i').addClass("fa-eye");
            }
      });

      window.avatarInitalsRender = function avatarInitalsRender() {
            var colors = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"];

            $('.avatar-initials').each(function (i, obj) {
                  obj = $(this);
                  var avatarWidth = obj.attr('width');
                  var avatarHeight = obj.attr('height');

                  var name = obj.data('name');

                  if (name.split(' ').length >= 2) {
                        var initials = name.split(' ')[0].charAt(0).toUpperCase() + name.split(" ")[1].charAt(0).toUpperCase();
                  } else {
                        var initials = name.split(' ')[0].charAt(0).toUpperCase();
                  }

                  var charIndex = initials.charCodeAt(0) - 65;
                  var colorIndex = charIndex % 19;

                  obj.css({
                        'background-color': colors[colorIndex],
                        'width': avatarWidth,
                        'height': avatarHeight,
                        'font': avatarWidth / 2 + "px Arial",
                        'color': '#FFF',
                        'textAlign': 'center',
                        'lineHeight': avatarHeight + 'px',
                        'borderRadius': '50%'
                  }).html('<span>' + initials + '</span>');
            });
      };

      avatarInitalsRender();

});

window.secondsToStr = function secondsToStr(seconds) {
      // TIP: to find current time in milliseconds, use:
      // var  current_time_milliseconds = new Date().getTime();

      var levels = [
            [Math.floor(seconds / 31536000), 'years'],
            [Math.floor((seconds % 31536000) / 86400), 'days'],
            [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
            [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
            [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
      ];
      var returntext = '';

      for (var i = 0, max = levels.length; i < max; i++) {
            if (levels[i][0] === 0) continue;
            returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]);
      };

      return returntext.trim();
}